import React from "react";
import Input from "./Input";
import Dropdown from "./Dropdown";
import Button from "./Button";

interface SectionHeaderProps {
  title: string;
  isDropdown?: boolean;
  dropdownData?: { label: string, value?: string }[];
  isViewAll?: boolean;
  isInput?: boolean;
  inputPlaceholder?: string;
  inptutValue?: string;
  setInputValue?: (value: string) => void;
  showBorder?: boolean;
  isButton?: boolean;
  onClick?: () => void;
}

const SectionHeader = ({
  title,
  isDropdown,
  dropdownData,
  isViewAll,
  isInput,
  inputPlaceholder,
  inptutValue,
  setInputValue,
  showBorder,
  isButton,
  onClick
}: SectionHeaderProps) => {
  return (
    <div className="bg-white pt-3 sticky top-0 z-10">
      <div className="flex items-center justify-between">
        <h1 className="text-base font-medium leading-4">{title}</h1>
        {isViewAll && (
          <div className="text-sm text-[#4096FF] cursor-pointer px-3 py-[7px] rounded-lg leading-5">
            View All
          </div>
        )}
        {isDropdown && (
          <div className="px-3 py-1 border border-[#D9D9D9] rounded-lg">
            <Dropdown data={dropdownData ?? []} selectedValue={inptutValue ?? ""} setSelectValue={(value) => setInputValue?.(value)} />
          </div>
        )}
        {isInput && (
          <Input
            placeholder={inputPlaceholder || "Search by order id"}
            value={inptutValue ?? ""}
            onChange={setInputValue ?? (() => {})}
            type="text"
            label=""
            className="w-[150px]"
          />
        )}
        {
          isButton && (
            <Button
              label="Create New"
              onClick={onClick ?? (() => {})}
              className="!w-auto px-5 !mt-0 !py-2 bg-[#4096FF] text-white rounded-lg"
            />
          )
        }
      </div>
      {showBorder && <hr className="my-3 border-[#F0F0F0]" />}
    </div>
  );
};

export default SectionHeader;
