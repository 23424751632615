import React, { useState } from "react";

// Define the type for each slice of the pie chart
interface PieSlice {
  count: number;
  color: string;
}

interface TooltipPosition {
  x: number;
  y: number;
}

interface PieChartProps {
  data: PieSlice[];
}

const PieChart: React.FC<PieChartProps> = (props: PieChartProps) => {
  const { data } = props;
  // State to manage the hovered slice and tooltip position
  const [hoveredSlice, setHoveredSlice] = useState<PieSlice | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition | null>(null);

  // Data for the pie chart
  // const data: PieSlice[] = [
  //   { value: 10, color: "#1a237e" }, // Dark blue
  //   { value: 40, color: "#e53935" }, // Red
  //   { value: 30, color: "#42a5f5" }, // Light blue
  //   { value: 20, color: "#0d47a1" }, // Medium blue
  // ];

  // Sum of all the values
  const total = data.reduce((sum, slice) => sum + slice.count, 0);

  // Helper to calculate pie slices
  const calculateArcs = (data: PieSlice[]) => {
    let cumulativeValue = 0;
    return data.map((slice) => {
      const [startX, startY] = getCoordinatesForPercent(cumulativeValue / total, 1); // Outer radius
      const [innerStartX, innerStartY] = getCoordinatesForPercent(cumulativeValue / total, 0.75); // Increased inner radius to reduce ring width
      cumulativeValue += slice.count;
      const [endX, endY] = getCoordinatesForPercent(cumulativeValue / total, 1); // Outer radius
      const [innerEndX, innerEndY] = getCoordinatesForPercent(cumulativeValue / total, 0.75); // Increased inner radius to reduce ring width
      const largeArcFlag = slice.count / total > 0.5 ? 1 : 0;

      return {
        ...slice,
        path: `
          M ${startX} ${startY} 
          A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY} 
          L ${innerEndX} ${innerEndY} 
          A 0.75 0.75 0 ${largeArcFlag} 0 ${innerStartX} ${innerStartY} 
          Z`,
      };
    });
  };

  // Calculate arcs based on data
  const arcs = calculateArcs(data);

  // Function to get coordinates for a given percentage and radius
  function getCoordinatesForPercent(percent: number, radius: number): [number, number] {
    const x = Math.cos(2 * Math.PI * percent) * radius;
    const y = Math.sin(2 * Math.PI * percent) * radius;
    return [x, y];
  }

  // Function to handle mouse move and update tooltip position
  const handleMouseMove = (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
    setTooltipPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div style={{ position: "relative", width: "170px", height: "170px" }}>
      <svg viewBox="-1 -1 2 2" style={{ transform: "rotate(-90deg)" }}>
        {arcs.map((slice, index) => (
          <path
            key={index}
            d={slice.path}
            fill={slice.color}
            onMouseEnter={() => setHoveredSlice(slice)}
            onMouseMove={handleMouseMove} // Update tooltip position on mouse move
            onMouseLeave={() => {
              setHoveredSlice(null);
              setTooltipPosition(null); // Hide tooltip when mouse leaves
            }}
          />
        ))}
      </svg>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <p style={{ fontSize: "24px", fontWeight: "600", margin: "0" }}>{data.reduce((acc, item) => acc + item.count, 0)}</p>
        <p style={{fontSize: "12px", fontWeight: "500", color: "black", opacity: "0.45"}}>Total Trips</p>
      </div>
      {hoveredSlice && tooltipPosition && (
        <div
          style={{
            position: "fixed", // Use fixed positioning to avoid offset issues
            top: tooltipPosition.y + 10, // Add a small offset from the cursor
            left: tooltipPosition.x + 10,
            backgroundColor: "white",
            opacity: 0.8,
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            pointerEvents: "none",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {hoveredSlice.count}
        </div>
      )}
    </div>
  );
};

export default PieChart;
