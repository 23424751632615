import React, { useEffect, useState } from "react";
import RecentOrders from "./RecentOrders";
import LoadingTrucks from "./LoadingTrucks";
import LatestShipping from "./LatestShipping";
import UnloadingCargo from "./UnloadingCargo";
import AvailableCargo from "./AvailableCargo";
import OrderRequests from "./OrderRequests";
import { useLoader } from "../../../../context/LoderContext";
import { fetchDailyOrders, fetchOrders } from "../../../../service/order";
import { getMonthWithMonthNumber } from "../../../../utils/staticData";
import { AvailableTrips, OrderDetails, ShippingData, TripCounts } from "../../../../service/type";
import { getItem } from "../../../../utils/localStorage";
import { fetchAvailableTrips, fetchTripCounts } from "../../../../service/trip";

interface User {
  first_name?: string;
  last_name?: string;
  role?: string;
}

const Home = () => {

  const currentMonth = new Date().getUTCMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(getMonthWithMonthNumber(currentMonth));
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [latestShippingData, setLatestShippingData] = useState<ShippingData[]>([]);
  const [availableTrips, setAvailableTrips] = useState<AvailableTrips[]>([]);
  const [tripCounts, setTripCounts] = useState<TripCounts[]>([]);

  const { showLoader, hideLoader } = useLoader();

  const getDailyOrders = async () => {
      showLoader();
      const res = await fetchDailyOrders(selectedMonth);
      setOrderDetails(res.data);
  }

  const getOrders = async () => {
      showLoader();
      const filterQuery = `page=1&limit=10`;
      const response = await fetchOrders(filterQuery);
      setLatestShippingData(response.data.orders);
  }

  const getAvailableTips = async () => {
    showLoader();
    const response = await fetchAvailableTrips();
    setAvailableTrips(response.data)
  }

  const getTripCounts = async () => {
    showLoader();
    const response = await fetchTripCounts();
    setTripCounts(response.data);
  }

  useEffect(() => {
    if(orderDetails && latestShippingData && availableTrips && tripCounts) {
      hideLoader();
    }
  }, [orderDetails, latestShippingData, availableTrips, tripCounts])


  useEffect(() => {
    getDailyOrders();
  },[selectedMonth])

  useEffect(() => {
    getOrders();
    getAvailableTips();
    getTripCounts();
  }, [])


  return (
    <>
      <div className="flex gap-3 w-full">
        <div className="flex w-3/4 flex-col gap-3">
          <div className="flex gap-3 w-full">
            <RecentOrders orderDetails={orderDetails} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth}/>
            <LoadingTrucks tripCounts={tripCounts}/>
          </div>
          <div>
            <LatestShipping latestShippingData={latestShippingData}/>
          </div>
          <div className="flex gap-3 w-full">
            <UnloadingCargo unloadingTrips={availableTrips}/>
            <AvailableCargo availableTrips={availableTrips} />
          </div>
        </div>
        <div className="w-1/4">
          {/* <OrderRequests /> */}
          <OrderRequests latestShippingData={latestShippingData}/>
        </div>
      </div>
    </>
  );
};

export default Home;
