import axiosInstance from "../axios/AxiosInstance";

export const fetchAvailableTrips = async () => {
    try {
        const response = await axiosInstance.get("/trip/available-trips");
        return response.data;
    } catch (error) {
        return error;
    }
}

export const fetchTripCounts = async () => {
    try {
        const response = await axiosInstance.get("/trip/trip-counts");
        return response.data;
    } catch (error) {
        return error;
    }
}