import { ImageWithBorder } from "../../../../components/common/ImageWithBorder";
import SectionHeader from "../../../../components/common/SectionHeader";
import { unloadingCargoData } from "../../../../utils/staticData";
import ExitIcon from "../../../../assets/icons/exit.svg";
import { statusBgColor } from "../../../../utils/statusBgColor";
import { AvailableTripsProps } from "../../../../service/type";
import { getStatusName } from "../../../../utils";
import dayjs from "dayjs";

const UnloadingCargo = ({unloadingTrips}: AvailableTripsProps) => {
  const unloadingTripsFilters = unloadingTrips?.filter(cargo => cargo.trip_status !== "pending").slice(0, 5);
  return (
    <div className="bg-white w-1/2 px-4 pb-3 rounded-lg">
      <SectionHeader title="Unloading Cargo" isViewAll showBorder />
      {unloadingTripsFilters?.map((cargo, index) => (
        <div className="flex items-center justify-between mt-2 " key={index}>
          <h1 className="text-xs text-black opacity-65 uppercase leading-6">
            {cargo.trip_number}
          </h1>
          <div className="flex flex-row items-center gap-3">
            <h1 className="text-xs leading-6 w-12 text-ellipsis overflow-hidden">
              {cargo.diparture_warehouse_name}
            </h1>
            <div className="border-2 border-[#003EB3] w-10 mt-1"></div>
            <h1 className="text-xs leading-6 w-12 text-ellipsis overflow-hidden">
              {cargo.arrival_warehouse_name}
            </h1>
          </div>
          <div
            className={`text-xs w-20 border text-center px-2 py-1 bg-opacity-30 rounded-md ${statusBgColor(
              cargo.trip_status
            )}`}
          >
            {getStatusName(cargo.trip_status)}
          </div>
          <h1 className="text-xs text-black leading-6">{dayjs(cargo.trip_date).format("DD MMM YYYY")}</h1>
          <ImageWithBorder src={ExitIcon} alt="Exit icon" isBorder />
          <ImageWithBorder src={ExitIcon} alt="Exit icon" isBorder />
        </div>
      ))}
    </div>
  );
};

export default UnloadingCargo;
