import axiosInstance from "../axios/AxiosInstance";
import { createOrderType } from "./type";

export const createOrder = async (orderData: createOrderType) => {
    try {
        const response = await axiosInstance.post("/order/create-order", orderData);
        return response.data;
    }
    catch (error) {
        return error;
    }
};

export const fetchOrders = async (filterQuery: any) => {
    try {
        const response = await axiosInstance.get(`/order/get-orders?${filterQuery}`);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const fetchDailyOrders = async (month: string) => {
    try {
        const response = await axiosInstance.get(`/order/daily-orders?month=${month}`);
        return response.data;
    } catch (error) {
        return error;
    }
}