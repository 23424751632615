import React from 'react'
import fileIcon from '../../../../assets/icons/file.svg'
import historyIcon from '../../../../assets/icons/history.svg'
import SectionHeader from '../../../../components/common/SectionHeader'
import { ImageWithBorder } from '../../../../components/common/ImageWithBorder'
import OrderRequestCard from './OrderRequestCard'
import { LatestShippingProps } from '../../../../service/type'

const OrderRequests = ({latestShippingData}: LatestShippingProps) => {
  const [inputValue, setInputValue] = React.useState('')
  return (
    <div className="bg-white h-full px-4 py-3 rounded-lg">
        <SectionHeader title='Order Requests' isInput inptutValue={inputValue} setInputValue={setInputValue} showBorder />
        <div className='flex items-center justify-between'>
          <h1 className='text-sm text-black opacity-65 leading-6'>Recent Order Requests</h1>
          <div className='flex gap-1'>
            <ImageWithBorder src={fileIcon} alt='file' isBorder/>
            <ImageWithBorder src={historyIcon} alt='history'/>
          </div>
        </div>
        {
          latestShippingData.slice(0,5).map((order, index) => (
            <OrderRequestCard key={index} order={order} />
          ))
        }
    </div>
  )
}

export default OrderRequests