import React, { useEffect, useState } from "react";
import Modal from "../components/common/Modal";
import Input from "../components/common/Input";
import Dropdown from "../components/common/Dropdown";
import { useLoader } from "../context/LoderContext";
import { getWarehouse } from "../service/warehouse";
import Button from "../components/common/Button";
import { createOrder } from "../service/order";
import { createOrderType } from "../service/type";

interface CreateOrderProps {
  openModal: boolean;
  setIsOpen: (value: boolean) => void;
}

const CreateOrder = (props: CreateOrderProps) => {
  const { openModal, setIsOpen } = props;
  const [formData, setFormData] = useState<{
    order_weight: string;
    diparture_warehouse_id: string;
    arrival_warehouse_id: string;
    order_date?: string;
  }>({
    order_weight: "",
    diparture_warehouse_id: "",
    arrival_warehouse_id: "",
    order_date: "",
  });
  const [warehouseData, setWarehouseData] = useState<any[]>([]);

  const { showLoader, hideLoader } = useLoader();

  const getAllWarehouse = async () => {
    showLoader();
    const warehouse = await getWarehouse();
    hideLoader();
    if (warehouse.code === 200) {
      const filteredWarehouseData = warehouse.data.map(
        (item: {
          address: { city: string; latitude: string; longitude: string };
          name: string;
          _id: string;
        }) => ({
          label: item.name,
          value: item._id,
          city: item.address.city,
          latitude: item.address.latitude,
          longitude: item.address.longitude,
        })
      );

      if (filteredWarehouseData.length > 0) {
        filteredWarehouseData.unshift({ label: "Select Warehouse", value: "" });
      } else {
        filteredWarehouseData.push({ label: "No warehouse found", value: "" });
      }

      setWarehouseData(filteredWarehouseData);
    }
  };

  useEffect(() => {
    getAllWarehouse();
  }, []);

  const handleSubmit = async () => {
    showLoader();
    const filteredOrderData: createOrderType = {
      order_weight: Number(formData.order_weight),
      diparture_warehouse_id: formData.diparture_warehouse_id,
      arrival_warehouse_id: formData.arrival_warehouse_id,
      ...(formData.order_date && { order_date: formData.order_date }),
    };
    const response = await createOrder(filteredOrderData);
    if (response.code === 200) {
        hideLoader()
        setIsOpen(false);
    }
  };

  return (
    <>
      <Modal isOpen={openModal} onClose={() => setIsOpen(false)}>
        <div className="w-[400px] max-h-4/5 bg-white rounded-md p-1">
          <div className="w-full h-full p-4 overflow-y-scroll">
            <Input
              value={formData.order_weight}
              onChange={(value) =>
                setFormData({ ...formData, order_weight: value })
              }
              placeholder="Order Weight"
              type="number"
              isLabel
              label="Order Weight"
              className="w-full mt-4"
            />
            <Dropdown
              data={warehouseData}
              isBorder
              isLabel
              label="Select Departure Warehouse"
              setSelectValue={(value) =>
                setFormData((prevState) => ({
                  ...prevState,
                  diparture_warehouse_id: value,
                }))
              }
              selectedValue={formData.diparture_warehouse_id}
            />
            <Dropdown
              data={warehouseData}
              isBorder
              isLabel
              label="Select Destination Warehouse"
              setSelectValue={(value) =>
                setFormData((prevState) => ({
                  ...prevState,
                  arrival_warehouse_id: value,
                }))
              }
              selectedValue={formData.arrival_warehouse_id}
            />
            <Button
              label="Create Order"
              onClick={handleSubmit}
              disabled={
                !formData.order_weight ||
                !formData.diparture_warehouse_id ||
                !formData.arrival_warehouse_id
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateOrder;
