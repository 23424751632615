import React, { useState } from "react";
import OrderBox from "../../../../assets/icons/orderBox.svg";
import roundedWithBorder from "../../../../assets/icons/roundedWithBorder.svg";
import rounded from "../../../../assets/icons/rounded.svg";
import location from "../../../../assets/icons/location.svg";
import Map from "../../../../components/common/Map";
import Modal from "../../../../components/common/Modal";
import dayjs from "dayjs";

// const Map = dynamic(() => import("../../../components/common/Map"), {
//   ssr: false,
// });

interface Props {
  order: {
    order_number: string;
    diparture_date: string;
    diparture_warehouse: {
      latitude: number;
      longitude: number;
      name: string;
    };
    arrival_warehouse: {
      latitude: number;
      longitude: number;
      name: string;
    };
    created_by: {
      first_name: string;
      last_name: string;
    };
  }
}

const OrderRequestCard = ({order}: Props) => {
  const { order_number, diparture_date, diparture_warehouse, arrival_warehouse, created_by } = order;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen && (
        <Modal isOpen onClose={() => setIsOpen(false)}>
          <div className="w-[400px] h-[400px] rounded-lg overflow-hidden">
            <Map
              dipartureLatitude={diparture_warehouse.latitude}
              dipartureLongitude={diparture_warehouse.longitude}
              destinationLatitude={arrival_warehouse.latitude}
              destinationLongitude={arrival_warehouse.longitude}
              showRouting
            />
          </div>
        </Modal>
      )}
      <div className="w-full bg-[#FFF1F0] rounded-lg py-3 mt-3">
        <div className="flex items-center justify-between px-3">
          <div className="flex items-center gap-2">
            <img src={OrderBox} alt="Order Box" width={30} height={30} />
            <h1 className="text-xs font-medium leading-6 uppercase">
              {order_number}
            </h1>
          </div>
          <h1 className="text-xs leading-6">{dayjs(diparture_date).format("DD MMM YYYY HH:mm")}</h1>
        </div>
        <hr className="border-dashed border-[#0000001F] mt-3" />
        <div className="flex flex-row w-full px-4 py-2 items-center justify-between">
          <div>
            <div className="flex items-start gap-2">
              <div className="flex flex-col h-full items-center mt-2 gap-[0px]">
                <img src={roundedWithBorder} alt="Rounded With Border" />
                <div className="border-l-2 border-dotted border-gray-300 h-3"></div>
                <img src={rounded} alt="Rounded" />
              </div>
              <div>
                <h1 className="text-[10px] text-black opacity-45 leading-6">
                  Pickup Location
                </h1>
                <h1 className="text-xs font-medium text-black leading-0">
                 {diparture_warehouse.name}
                </h1>
              </div>
            </div>
            <div className="border-l-2 border-dotted border-gray-300 h-3 ml-[4.5px]"></div>
            <div className="flex items-start gap-2">
              <img src={location} alt="Location" className="mt-[2px]" />
              <div>
                <h1 className="text-[10px] text-black opacity-45">
                  Destination
                </h1>
                <h1 className="text-xs font-medium text-black leading-6">
                  {arrival_warehouse.name}
                </h1>
              </div>
            </div>
          </div>
          <div
            className="w-20 h-20 rounded-lg overflow-hidden border border-[#D9D9D9] z-10"
            onClick={() => setIsOpen(true)}
          >
            <Map
              dipartureLatitude={diparture_warehouse.latitude}
              dipartureLongitude={diparture_warehouse.longitude}
              destinationLatitude={arrival_warehouse.latitude}
              destinationLongitude={arrival_warehouse.longitude}
            />
          </div>
        </div>
        <hr className="border-dashed border-[#0000001F]" />
        <div className="flex px-4 mt-3 justify-between items-center">
          <div className="flex gap-1 items-center">
            <img src={OrderBox} alt="Rounded" />
            <h1 className="text-sm font-medium leading-6">{created_by.first_name} {created_by.last_name}</h1>
          </div>
          <h1 className="text-xs text-[#1677FF] cursor-pointer leading-5">View Details</h1>
        </div>
      </div>
    </>
  );
};

export default OrderRequestCard;
