import plusIcon from "../assets/icons/plus.svg";
import notificationIcon from "../assets/icons/notification.svg";
import inboxIcon from "../assets/icons/inboxOutlined.svg";
import homeIcon from "../assets/icons/homeIcon.svg";
import pieChartIcon from "../assets/icons/pieChart.svg";
import sendIcon from "../assets/icons/sendOutlined.svg";
import profileIcon from "../assets/icons/profile.svg";
import settingsIcon from "../assets/icons/settings.svg";
import cargo from "../assets/icons/cargo.svg";
import routeIcon from "../assets/icons/routeLocation.svg";
import createRoute from "../assets/icons/createRoute.svg";
import addStaff from "../assets/icons/addStaff.svg";
import addWarehouse from "../assets/icons/warehouse.svg";

export const orderData = [
  { date: "01", order: 190 },
  { date: "02", order: 30 },
  { date: "03", order: 60 },
  { date: "04", order: 120 },
  { date: "05", order: 150 },
  { date: "06", order: 200 },
  { date: "07", order: 220 },
  { date: "08", order: 180 },
  { date: "09", order: 190 },
  { date: "10", order: 210 },
  { date: "11", order: 230 },
  { date: "12", order: 250 },
  { date: "13", order: 260 },
  { date: "14", order: 200 },
  { date: "15", order: 150 },
  { date: "16", order: 20 },
  { date: "17", order: 80 },
  { date: "18", order: 100 },
  { date: "19", order: 110 },
  { date: "20", order: 90 },
  { date: "21", order: 150 },
  { date: "22", order: 170 },
  { date: "23", order: 190 },
  { date: "24", order: 210 },
  { date: "25", order: 230 },
  { date: "26", order: 240 },
  { date: "27", order: 250 },
  { date: "28", order: 260 },
  { date: "29", order: 270 },
  { date: "30", order: 230 },
  { date: "31", order: 230 },
];

export const getMonthWithMonthNumber = (month: number) => {
  switch (month) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      return "Jan";
  }
};

export const monthData = [
  {
    label: "Jan",
  },
  {
    label: "Feb",
  },
  {
    label: "Mar",
  },
  {
    label: "Apr",
  },
  {
    label: "May",
  },
  {
    label: "Jun",
  },
  {
    label: "Jul",
  },
  {
    label: "Aug",
  },
  {
    label: "Sep",
  },
  {
    label: "Oct",
  },
  {
    label: "Nov",
  },
  {
    label: "Dec",
  },
];

export const orderDetails = [
  {
    label: "Active Orders",
    quantity: 720,
  },
  {
    label: "Pending Req",
    quantity: 120,
  },
  {
    label: "Accepted",
    quantity: 450,
  },
];

export const headerIcon = [
  {
    icon: plusIcon,
    alt: "plusIcon",
    click: () => {},
  },
  {
    icon: notificationIcon,
    alt: "notification icon",
    click: () => {},
  },
  {
    icon: inboxIcon,
    alt: "inbox icon",
    click: () => {},
  },
];

export const sidebarIcon = [
  {
    name: "Dashboard",
    icon: homeIcon,
    link: "/dashboard",
  },
  {
    name: "Pie Chart",
    icon: pieChartIcon,
    link: "/profile",
  },
  {
    name: "Send",
    icon: sendIcon,
    link: "/settings",
  },
  {
    name: "Route",
    icon: routeIcon,
    link: "/route",
  },
  {
    name: "Notification",
    icon: notificationIcon,
    link: "/notification",
  },
  {
    name: "Profile",
    icon: profileIcon,
    link: "/profile",
  },
  {
    name: "Settings",
    icon: settingsIcon,
    link: "/settings",
  },
];

export const truckData = [
  {
    label: "Pending",
    quantity: 40,
    color: "#001D66",
  },
  {
    label: "Arrived",
    quantity: 23,
    color: "#4096FF",
  },
  {
    label: "Completed",
    quantity: 12,
    color: "#003EB3",
  },
  {
    label: "Ready to load",
    quantity: 12,
    color: "#69B1FF",
  },
  {
    label: "Ready to Unload",
    quantity: 3,
    color: "#1677FF",
  },
  {
    label: "Cancelled",
    quantity: 3,
    color: "#DC4446",
  },
];

  export const availableCargoData = [
    {
      name: "shipid01",
      availability: 45,
      source: "Ahmedabad",
      destination: "Delhi",
    },
    {
      name: "shipid02",
      availability: 65,
      source: "Ahmedabad",
      destination: "Cheenai",
    },
    {
      name: "shipid03",
      availability: 75,
      source: "Mumbai",
      destination: "Delhi",
    },
    {
      name: "shipid04",
      availability: 85,
      source: "Kanpur",
      destination: "Pune",
    },
    {
      name: "shipid05",
      availability: 95,
      source: "Mumbai",
      destination: "Chandigarh",
    },
  ]

  export const unloadingCargoData = [
    {
      name: "shipid01",
      source: "Mumbai",
      destination: "Ahmedabad",
      status: "Delivered",
      date: "17 July 2024",
    },
    {
      name: "shipid02",
      source: "Kanpur",
      destination: "Visakhapatnam",
      status: "Canceled",
      date: "17 July 2024",
    },
    {
      name: "shipid03",
      source: "Lakhnau",
      destination: "Kolkata",
      status: "Active",
      date: "17 July 2024",
    },
    {
      name: "shipid04",
      source: "Mumbai",
      destination: "Varanasi",
      status: "Delivered",
      date: "17 July 2024",
    },
    {
      name: "shipid05",
      source: "Vadodara",
      destination: "Surat",
      status: "Canceled",
      date: "17 July 2024",
    },
  ];

export const orderRequests = [
  {
    orderId: "orderid01",
    date: "17 July 2024 18:00",
    pickupLocation: "Delhi",
    destination: "Mumbai",
    companyName: "Raj Industries",
    dipartureLatitude: 28.6139,
    dipartureLongitude: 77.209,
    destinationLatitude: 19.076,
    destinationLongitude: 72.8777,
  },
  {
    orderId: "orderid02",
    date: "18 July 2024 09:30",
    pickupLocation: "Bangalore",
    destination: "Hyderabad",
    companyName: "Tech Solutions",
    dipartureLatitude: 12.9716,
    dipartureLongitude: 77.5946,
    destinationLatitude: 17.385,
    destinationLongitude: 78.4867,
  },
  {
    orderId: "orderid03",
    date: "19 July 2024 12:15",
    pickupLocation: "Kolkata",
    destination: "Chennai",
    companyName: "Delta Freight",
    dipartureLatitude: 22.5726,
    dipartureLongitude: 88.3639,
    destinationLatitude: 13.0827,
    destinationLongitude: 80.2707,
  },
  {
    orderId: "orderid04",
    date: "20 July 2024 14:45",
    pickupLocation: "Pune",
    destination: "Ahmedabad",
    companyName: "Logistics Co.",
    dipartureLatitude: 18.5204,
    dipartureLongitude: 73.8567,
    destinationLatitude: 23.0225,
    destinationLongitude: 72.5714,
  },
  {
    orderId: "orderid05",
    date: "21 July 2024 16:00",
    pickupLocation: "Jaipur",
    destination: "Udaipur",
    companyName: "Rajasthan Cargo",
    dipartureLatitude: 19.2319,
    dipartureLongitude: 73.7182,
    destinationLatitude: 24.5854,
    destinationLongitude: 73.7125,
  },
];

export const businessModalData = [
  {
    icon: cargo,
    alt: "add_cargo",
    title: "Add Cargo",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    role: ["superAdmin"],
  },
  {
    icon: createRoute,
    alt: "create_route",
    title: "Create Route",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    role: ["superAdmin"],
  },
  {
    icon: addStaff,
    alt: "add_staff",
    title: "Add Staff",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    role: ["superAdmin"],
  },
  {
    icon: addWarehouse,
    alt: "add_warehouse",
    title: "Add Warehouse",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    role: ["superAdmin"],
  },
  {
    icon: cargo,
    alt: "create_order",
    title: "Create Order",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    role: ["superAdmin", "member"],
  }
];

export const latestShippingData = [
  {
    orderId: "orderID001",
    status: "Delivered",
    customer: "John Doe",
    departure: "Mumbai",
    weight: "1000kg",
    arrival: "Ahmedabad",
    arrivalDate: "17 July 2024",
  },
  {
    orderId: "orderID002",
    status: "Active",
    customer: "Jane Smith",
    departure: "Delhi",
    weight: "1500kg",
    arrival: "Chennai",
    arrivalDate: "18 July 2024",
  },
  {
    orderId: "orderID003",
    status: "Active",
    customer: "Alice Johnson",
    departure: "Kolkata",
    weight: "1200kg",
    arrival: "Bangalore",
    arrivalDate: "19 July 2024",
  },
  {
    orderId: "orderID004",
    status: "Delivered",
    customer: "Bob Brown",
    departure: "Hyderabad",
    weight: "800kg",
    arrival: "Pune",
    arrivalDate: "20 July 2024",
  },
  {
    orderId: "orderID005",
    status: "Canceled",
    customer: "Charlie Davis",
    departure: "Ahmedabad",
    weight: "900kg",
    arrival: "Surat",
    arrivalDate: "21 July 2024",
  },
  {
    orderId: "orderID006",
    status: "Active",
    customer: "Diana Evans",
    departure: "Jaipur",
    weight: "1100kg",
    arrival: "Lucknow",
    arrivalDate: "22 July 2024",
  },
  {
    orderId: "orderID007",
    status: "Delivered",
    customer: "Ethan Foster",
    departure: "Bhopal",
    weight: "1300kg",
    arrival: "Indore",
    arrivalDate: "23 July 2024",
  },
  {
    orderId: "orderID008",
    status: "Delivered",
    customer: "Fiona Green",
    departure: "Patna",
    weight: "1400kg",
    arrival: "Ranchi",
    arrivalDate: "24 July 2024",
  },
  {
    orderId: "orderID009",
    status: "Canceled",
    customer: "George Harris",
    departure: "Chandigarh",
    weight: "1600kg",
    arrival: "Amritsar",
    arrivalDate: "25 July 2024",
  },
  {
    orderId: "orderID010",
    status: "Active",
    customer: "Hannah Jackson",
    departure: "Guwahati",
    weight: "1700kg",
    arrival: "Shillong",
    arrivalDate: "26 July 2024",
  },
];
