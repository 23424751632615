import SectionHeader from "../../../../components/common/SectionHeader";
import { statusBgColor } from "../../../../utils/statusBgColor";
import dayjs from "dayjs";
import { getStatusName } from "../../../../utils";
import { LatestShippingProps } from "../../../../service/type";

const LatestShipping = ({latestShippingData}: LatestShippingProps) => {
  return (
    <div className="bg-white min-h-[628px] px-6 py-4 rounded-lg shadow-md">
      <SectionHeader title="Latest Shipping" isViewAll showBorder />
      <div className="flex items-center justify-between mt-4">
        <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/6 px-2">
          Order ID
        </h1>
        <h1 className="text-xs text-gray-600 font-semibold uppercase w-[12%] px-2">
          Status
        </h1>
        <div className="flex items-center justify-between w-3/4">
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/4 px-8">
            Name
          </h1>
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/6 px-2">
            Weight
          </h1>
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/6 px-2">
            Departure
          </h1>
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/6 px-2">
            Arrival
          </h1>
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/5 px-2">
            Departure Date
          </h1>
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/5 px-2">
            Arrival Date
          </h1>
        </div>
      </div>
      <hr className="my-3 border-gray-200" />
      {latestShippingData.map((shipping, index) => (
        <div key={index}>
          <div className="flex items-center justify-between mt-2">
            <h1 className="text-xs text-black opacity-65 uppercase leading-6 w-1/6 px-2">
              {shipping.order_number}
            </h1>
            <div
              className={`text-xs border text-center first-letter:uppercase px-3 py-1 bg-opacity-30 rounded-md w-[12%] ${statusBgColor(
                shipping.order_status
              )}`}
            >
              {getStatusName(shipping.order_status)}
            </div>
            <div className="flex items-center justify-between w-3/4">
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/4 px-8">
                {shipping.created_by.first_name} {shipping.created_by.last_name}
              </h1>
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/6 px-2">
                {shipping.order_weight}kg
              </h1>
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/6 px-2">
                {shipping.diparture_warehouse.name}
              </h1>
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/6 px-2">
                {shipping.arrival_warehouse.name}
              </h1>
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/5 px-2">
                {dayjs(shipping.diparture_date).format("DD-MM-YYYY HH:mm")}
              </h1>
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/5 px-2">
                {dayjs(shipping.delivery_date).format("DD-MM-YYYY HH:mm")}
              </h1>
            </div>
          </div>
          {index !== latestShippingData.length - 1 && (
            <hr className="my-3 border-gray-200" />
          )}
        </div>
      ))}
    </div>
  );
};

export default LatestShipping;
