export const statusBgColor = (status: string) => {
    switch (status) {
      case "delivered":
        return "bg-[#ADC6FF] border-[#ADC6FF] text-[#2F54EB] ";
      case "cancelled":
        return "bg-[#FFA39E] border-[#FF6666] text-[#F5222D]";
      case "out_for_delivery":
        return "bg-[#B7EB8F] border-[#7CB305] text-[#52C41A]";
      case "pending":
        return "bg-[#FFD591] border-[#FFA940] text-[#FA8C16]";
      case "unloading":
        return "bg-[#FFD591] border-[#FFA940] text-[#FA8C16]";
      case "packing":
        return "bg-[#FFD591] border-[#FFA940] text-[#FA8C16]";
      case "completed":
        return "bg-[#B7EB8F] border-[#7CB305] text-[#52C41A]";
      default:
        return "bg-white";
    }
};