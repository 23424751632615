export const getStatusName = (status: string) => {
    switch (status) {
        case "delivered":
            return "Delivered";
        case "cancelled":
            return "Cancelled";
        case "out_for_delivery":
            return "Out for delivery";
        case "pending":
            return "Pending";
        case "unloading":
            return "Unloading";
        case "packing":
            return "Packing";
        case "completed":
            return "Completed";
        default:
        return "Unknown";
    }
}

export const getUserRole = (role: string) => {
    switch (role) {
        case "superAdmin":
            return "Super Admin";
        case "member":
            return "Member";
        case "user":
            return "User";
        default:
            return "Unknown";
    }
}